import React from 'react'

import PropTypes from 'prop-types'

import { Link } from './style'

const NEW_WINDOW = '_blank'
const DATA_TEST_ID = 'link-button'

const LinkButton = props => {
  const { altText, buttonText, id, url } = props
  const testId = [
    DATA_TEST_ID,
    (id || '').replace(' ', '-').toLowerCase(),
  ].join('-')

  if (buttonText && url) {
    return (
      <Link
        aria-label={altText}
        data-testid={testId}
        href={url}
        target={NEW_WINDOW}
        title={altText}
      >
        {buttonText}
      </Link>
    )
  }

  return null
}

LinkButton.propTypes = {
  altText: PropTypes.string,
  buttonText: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
}

export default LinkButton
