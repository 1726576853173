import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from '../../utils/stringHelpers'
import {
  CheckBoxInputContainer,
  CheckBoxInputLabel,
  CheckBoxHidden,
  CheckBoxStyled,
} from './component.styles'
import CheckIcon from '../CheckIcon'

const CheckBox = ({
  alternateId = '',
  className,
  dataTestId,
  hideText = false,
  isChecked,
  onChange,
  text,
}) => {
  const [isAriaChecked, setIsAriaChecked] = useState(isChecked || false)

  const handleChange = e => {
    onChange(e)
    setIsAriaChecked(!isAriaChecked)
  }

  const keyHandler = e => {
    if (e.key === 'Enter') {
      e.target.checked = !isAriaChecked
      onChange(e)
      setIsAriaChecked(!isAriaChecked)
    }
  }

  return (
    <CheckBoxInputContainer className={className}>
      <CheckBoxHidden
        aria-checked={isAriaChecked}
        checked={isChecked}
        dataTestId={dataTestId}
        id={`input-checkbox-${alternateId}-${toUrlSafe(text)}`}
        name={`input-checkbox-${toUrlSafe(text)}`}
        onChange={handleChange}
        onKeyPress={keyHandler}
        type="checkbox"
        value={text}
      />
      <CheckBoxStyled>
        <CheckIcon />
      </CheckBoxStyled>
      {!hideText && (
        <CheckBoxInputLabel
          dataTestId={`${dataTestId}-label`}
          htmlFor={`input-checkbox-${alternateId}-${toUrlSafe(text)}`}
        >
          {text}
        </CheckBoxInputLabel>
      )}
    </CheckBoxInputContainer>
  )
}

CheckBox.propTypes = {
  alternateId: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  hideText: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}
export default CheckBox
